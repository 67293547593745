body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #1c2c3b;
}

.redTitle {
  color: #c1292e;
}

.formContainer {
  text-align: center;
}

.formContainer > input {
  margin: 1%;
  width: 30%;
  height: 25px;
  background-color: #c8c6d7;
  border: 2px solid #bfacc8;
}

.formContainer > button {
  height: 25px;
  width: 31.5%;
  background-color: #bfacc8;
  border: 2px solid #c8c6d7;
}

@media only screen and (max-width: 597px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .formContainer > input {
    width: 80%;
    margin: 1.5% 10%;
  }
  
  .formContainer > button {
    width: 81.5%;
    margin: 1.5% 10%;
  }

  #mainTitle {
    margin-top: 50px;
  }

  #navigation {
    margin: 10px;
  }  
}

#cardContainer {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  flex-flow: row;
  flex-wrap: wrap;
}

.card {
  width: 40%;
  max-width: 300px;
  min-width: 263px;
  margin: 20px;
  text-align: center;
  color: #f1d302;
}
.card > img {
  width: 100%;
  height: 200px;
  border: 5px solid #ffffff;
}
.card > button {
  width: 40%;
  margin: 5px 5%;
  background-color: #bfacc8;
  border: 2px solid #c8c6d7;
}

#container {
  max-width: 1200px;
  margin: auto;
}

#hidden {
  display: none;
}

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.sidenav a {
  margin-top: 10px;
  padding: 8px 32px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
  font-family: Arial, Helvetica, sans-serif;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.hamburger {
  width: 35px;
  height: 5px;
  background-color: #c1292e;
  margin: 6px 0;
}

@media only screen and (min-width: 598px) {
  #navigation {
    position: absolute;
    z-index: 2;
    margin: -15px 10px;
  }
}
  

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {font-size: 18px;}
}